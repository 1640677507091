import * as React from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function ActionAlerts({ alert, resetAlert }) {
  return (
    <Stack className='alert' spacing={2}>
      <Alert onClose={resetAlert} severity='info'>{alert}</Alert>
    </Stack>
  );
}