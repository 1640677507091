import moment from 'moment';
import Geocode from 'react-geocode';
import { sortByDistance } from 'sort-by-distance';

import { default_location } from './constants';
import bikeImg1 from '../assets/bike1.jpeg';
import bikeImg2 from '../assets/bike2.jpeg';
import bikeImg3 from '../assets/bike3.jpeg';
import bikeImg4 from '../assets/bike4.jpeg';

export const getMapBounds = (mapRef, dispatchMap) => {
  if (mapRef) {
    const ne = mapRef.getBounds().getNorthEast();
    const sw = mapRef.getBounds().getSouthWest();

    dispatchMap({ type: 'neBounds', payload: { lat: ne.lat(), lng: ne.lng() }});
    dispatchMap({ type: 'swBounds', payload: { lat: sw.lat(), lng: sw.lng() }});
    dispatchMap({ type: 'updatedCenter', payload: {
      lat: mapRef.getCenter().lat(),
      lng: mapRef.getCenter().lng() 
    }});
  }
};

export const getLatLng = async (location) => {
  try {
    Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
    const response = await Geocode.fromAddress(location);
    const result = response.results[0].geometry.location;
    return result;
  } 
  catch (error) {
    console.error(error);
  }
};

export const handleSearch = async (location, dispatchMap, zoom) => {
  const result = await getLatLng(location);
  dispatchMap({ type: 'center', payload: { lat: result.lat, lng: result.lng }});
  dispatchMap({ type: 'zoomLevel', payload: zoom });
};

export const resetFilters = (dispatch) => {
  dispatch({ type: 'keyword', payload: null });
  dispatch({ type: 'date1', payload: Date.now() });
  dispatch({ type: 'date2', payload: addDays(Date.now(), 100) });
  dispatch({ type: 'categories', payload: [] });
  dispatch({ type: 'alert', payload: null });
};

export const convertCategories = (events) => events.map(event => ({
  ...event,
  EventTypes: event.EventTypes.map(type => 
    type === 'Gravel Grinder' ? 'Gravel' :
    type === 'Road Race' ? 'Road' :
    type === 'Off Road' ? 'MTB' : type
  )
}));

export const findRegion = (center) => { 
  const lng = center ? center.lng : default_location.lng;

  if (lng >= -126 && lng <= -101) {
    return 1;
  } 
  else if (lng >= -101 && lng <= -89) {
    return 2;
  } 
  else if (lng >= -89 && lng <= -77) {
    return 3;
  } 
  else if (lng >= -77 && lng <= -53) {
    return 4;
  } 
  else {
    return 3;
  }
};

export const defaultCenter = (center) => center.lng ? center : default_location;

export function sortByCenterDistance(center, events) {
  const centerPt = { Latitude: center.lat, Longitude: center.lng }
  const opts = { yName: 'Latitude', xName: 'Longitude' }

  return sortByDistance(centerPt, events, opts);
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  
  return result;
}

export const getTitle = (categories) => {
  if (categories.length == 1) {
    return categories.includes('All Cycling') ? 'Cycling Events' : (categories[0] + ' Events');
  }
  else if (categories.length > 1) {
    return categories.includes('All Cycling') ? 'Cycling Events' : (categories.join('/') + ' Events');
  }
  else {
    return 'Cycling Events';
  }
};

export const getSearchString = (keyword, categories, date1, date2) => { 
  var searchString = 'Filters: ';
  if (keyword) {
    searchString += `${keyword}, `;
  }
  if (categories && categories.length > 0) {
    searchString += `${categories.join(', ')}, `;
  }
  if (date1) {
    searchString += `${moment(date1).format('MMMM Do')}, `;
  }
  if (date2) {
    searchString += `${moment(date2).format('MMMM Do')}`;
  }
  return searchString;
};

export function getThumbnail() {
  const thumbnails = [bikeImg1, bikeImg2, bikeImg3, bikeImg4];
  const random = Math.floor(Math.random() * thumbnails.length);
  
  return thumbnails[random];
}

export function getImage(event) {
  if (event.EventLogo) {
    return event.EventLogo;
  } 
  else if (event.CoverPhoto) {
    return event.CoverPhoto;
  }
  else {
    return getThumbnail();
  }
}

export function convertDate(dateString) {
  if (dateString.length < 20 || dateString.match(/^\d{1,2}\/\d{1,2}\/\d{4}/)) {
    return dateString;
  }
  const timestamp = dateString.substring(6, 19);
  const date = new Date(parseInt(timestamp) + 12*3600*1000);
  return date;
}

export function getEventStart(dateString) {
  return moment(convertDate(dateString)).format('MMMM Do, YYYY');
}

export function getEventDates(dateString1, dateString2) {
  const date1 = moment(convertDate(dateString1)).format('MMMM Do, YYYY');
  const date2 = moment(convertDate(dateString2)).format('MMMM Do, YYYY');
  const duration = moment(convertDate(dateString2)).diff(convertDate(dateString1), 'hours');

  return duration >= 12 ? `${date1} - ${date2}` : date1;
}

export function removeHTML(description) {
  return description && description.replace(/<[^>]*>/g, '');
}

export function getCity(location) {
  const regexExp = /([A-Za-z\s]+),\s*([A-Z]{2})\s*/;
  const city = location.match(regexExp)[0];

  return city;
}

export function getUrl(url) {
  if (url) {
    return url.match(/^http[s]?:\/\//) ? url : 'https://' + url;
  }
  else {
    return url;
  }
}
