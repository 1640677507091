import React from 'react';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';

import { getImage, getEventDates } from './services/helpers';

function EventCard({ event }) {
  const isDesktop = useMediaQuery('(min-width: 700px)');

  return (
    <Paper elevation={3} className='event-card-box'>
      {isDesktop ?  
        <Grid container>
          <Grid item sm={5.5}>
            <CardMedia
              className='event-img'
              component='img'
              image={getImage(event)}
            /> 
          </Grid>
          
          <Grid item sm={6.5} className='event-card'>
            <Box className='event-card-name'>
              <Link to={`/show-event/${event.EventName.replaceAll(' ', '-')}/${event._id}`} target='_blank' rel='noopener noreferrer'>
                {event.EventName}
              </Link>
            </Box>
            <Box className='event-card-date'>
              {getEventDates(event.EventDate, event.EventEndDate)}
            </Box>
            <Box className='event-card-city'>
              {event.EventCity}, {event.EventState}
            </Box>
            <Box className='event-card-types'>
              {event.EventTypes.toString().replace(',', ', ')}
            </Box>
          </Grid>
        </Grid>
          : 
        <Grid container>
          <Grid item className='event-img-box'>
            <Paper
              className='event-img'
              component='img'
              src={getImage(event)}
            /> 
          </Grid>
          
          <Grid item className='event-card'>
            <Box className='event-card-name'>
              <Link to={`/show-event/${event.EventName.replaceAll(' ', '-')}/${event._id}`} target='_blank' rel='noopener noreferrer'>
                {event.EventName}
              </Link>
            </Box>
            <Box className='event-card-date'>
              {getEventDates(event.EventDate, event.EventEndDate)}
            </Box>
            <Box className='event-card-city'>
              {event.EventCity}, {event.EventState}
            </Box>
            <Box className='event-card-types'>
              {event.EventTypes.toString().replace(',', ', ')}
            </Box>
          </Grid>
        </Grid>
      }
    </Paper>
  );
}

export default EventCard;