
export const categories = ['All Cycling', 'Group Rides', 'Gravel', 'Road', 'MTB', 'Bike Tour', 'BMX', 'Cycling Camp', 'Cyclocross', 'Fat Bike', 'Gran Fondo', 'Hill Climb', 'Multisport', 'Recreational', 'Special Event', 'Time Trial', 'Track', 'Virtual', 'Virtual Challenge'];
export const categoriesSelect = ['Group Rides', 'Gravel', 'Road', 'MTB', 'Bike Tour', 'BMX', 'Cycling Camp', 'Cyclocross', 'Fat Bike', 'Gran Fondo', 'Hill Climb', 'Multisport', 'Recreational', 'Special Event', 'Time Trial', 'Track', 'Virtual', 'Virtual Challenge'];

export const default_location = { lat: 30.3077609, lng: -97.7557424 };

export const content = 'Find the best bike events in the US with our interactive map! Search by location, filter by category and date, and join the cycling community today. Plan your adventure now.';


// export const bikeUrl = 'https://pedalradar-5vlga.ondigitalocean.app';
// export const bikeUrl = 'http://localhost:80';
// export const bikeUrl = 'https://events-mern.fly.dev';


