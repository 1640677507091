import React, { useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

function SearchBar({ handleClick }) {
  const inputRef = useRef(null);

  return (
    <Paper component='form' className='search-bar' onSubmit={(e, keyword) => handleClick(e, inputRef.current.value)}>
      <IconButton 
        onClick={(e, keyword) => handleClick(e, inputRef.current.value)} 
        type='button' 
        sx={{ p: '.2em .2em .2em .12em' }} 
      >
        <SearchIcon sx={{ color: '#62d1de' }} />
      </IconButton>
      <InputBase
        sx={{ 
          ml: .5,
          pt: .5,
          flex: 1,
          input: { color: '#62d1de', fontWeight: 600, fontSize: '1.05rem' },
          label: { color: '#62d1de' } 
        }}
        placeholder='Search Location'
        inputRef={inputRef}
      />
    </Paper>
  );
}

export default SearchBar;