import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

function AccountMenu({ setOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Paper sx={{ background: '#312f2f' }}>
      <Box className='account-menu'>
        <Tooltip title="Account">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon sx={{ width: 24, height: 24, mr: 1, color: '#62d1de' }} />
            <Avatar sx={{ width: 28, height: 28, color: '#62d1de', background: 'black' }} />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Link to='/create-event'>
            Register Event
          </Link>
        </MenuItem>
        <Divider sx={{ background: '#62d1de' }} />

        <MenuItem>
          <Link to='/contact'>
            Contact Us
          </Link>
        </MenuItem>
        <Divider sx={{ background: '#62d1de' }} />

        <MenuItem>
          <Link onClick={() => setOpen(true)}>
            Beta Test
          </Link>
        </MenuItem>

        {/* <Divider sx={{ background: '#62d1de' }} />

        <MenuItem>
          <Link to='/signin'>
            Admin
          </Link>
        </MenuItem> */}
      </Menu>
    </Paper>
  );
}

const paperProps = {
  elevation: 0,
  sx: {
    background: '#383737',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      background: '#383737',
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}

export default AccountMenu;