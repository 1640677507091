import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

function EventsModal({ events, open, handleClose }) {
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Paper sx={modalStyle}>
          <Button onClick={handleClose} variant='outlined' size='small' sx={{ ml: 2, mb: 1, border: '1px solid #62d1de' }}>
            Back to Map
          </Button>
          <List sx={{ maxHeight: '82vh', overflow: 'auto' }}>
            {events}
          </List>
          <Button onClick={handleClose} variant='outlined' size='small' sx={{ ml: 2, mt: 2, border: '1px solid #62d1de' }}>
            Close
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '.7em 1.5em',
  overflow:'scroll',
  height:'100%',
  display:'block'
};

export default EventsModal;