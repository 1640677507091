import React, { useReducer, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Alert from './Alert';
import { categoriesSelect } from './services/constants';
import { getLatLng } from './services/helpers';
import { initialState, reducer } from './store/store';

function CreateEvent() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [event, setEvent] = useState({ EventTypes: [] });
  const navigate = useNavigate();
  const { alert } = state;

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {
    getLatLng(`${data.EventAddress}, ${data.EventCity}, ${data.EventState} ${data.EventZip}`)      
      .then(res => {
        const newEvent = { ...data, ['Latitude']: res.lat, ['Longitude']: res.lng, ['EventTypes']: event.EventTypes };
        axios.post(`${process.env.REACT_APP_API_URL}/newBikeEvents`, newEvent)
          .then(res => {
            dispatch({ type: 'alert', payload: 'Event Registered!' });
            setEvent({ EventTypes: [] });
            reset();
            console.log(res.data);
          })
          .catch(error => {
            dispatch({ type: 'alert', payload: error.response.data.error.message });
            reset();
            console.log(error.response);
          });
      })
      .catch(error => {
        dispatch({ type: 'alert', payload: 'Error creating event' });
        reset();
        console.log(error.response);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };

  const handleClick = () => {
    navigate('/');
    window.location.reload();
  };

  const resetAlert = () => dispatch({ type: 'alert', payload: null });

  const content = 'Register your bike event on our interactive map! Search by location, filter by category and date, and join the cycling community today. Plan your adventure now.';
  
  return (
    <Container maxWidth='md' sx={{ mt: 2.5, mb: 2 }}>
      <Helmet>
        <title>Register Event</title>
        <meta name='description' content={content} />
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={4} className='event-page' sx={{ color: '#bbbbbb' }}>
        <Typography variant='h5' sx={{ m: '.25em 0em 0em .72em', fontSize: '1.57rem' }}>
          Register Event
        </Typography>
        <Box>
          {alert && <Alert alert={alert} resetAlert={resetAlert} />}  
        </Box>
      
        <CardContent sx={{ p: '.4em 1.2em .5em 1.2em' }}> 
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                {...register('EventName', { required: true, minLength: 3 })}
                required
                id='EventName'
                name='EventName'
                label='Event Name'
                fullWidth
                variant='standard'
              />
              {errors.EventName && <Box className='form-error'>Min Length: 3</Box>}
            </Grid>

            <Grid item xs={12}>
              <FormControl className='form-control' sx={{ border: '1px solid #62d1de', background: '#222222' }}>
                <InputLabel htmlFor='eventTypes' >Event Types</InputLabel>
                <Select
                  multiple
                  name='EventTypes'
                  value={event.EventTypes}
                  onChange={handleInputChange}
                  label='Categories'
                  renderValue={(selected) => selected.join(', ')}
                  sx={{ svg: { color: '#62d1de' } }}
                >
                  {categoriesSelect.map((category, i) => (
                    <MenuItem key={i} value={category}>
                      <Checkbox checked={event.EventTypes.indexOf(category) > -1} />
                      <ListItemText primary={category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register('EventAddress', { required: true, minLength: 3 })}
                required
                id='EventAddress'
                name='EventAddress'
                label='Event Address'
                fullWidth
                variant='standard'
              />
              {errors.EventAddress && <Box className='form-error'>Min Length: 3</Box>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...register('EventCity', { required: true, minLength: 3 })}
                required
                id='EventCity'
                name='EventCity'
                label='City'
                fullWidth
                variant='standard'
              />
              {errors.EventCity && <Box className='form-error'>Min Length: 3</Box>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...register('EventState', { required: true, minLength: 2 })}
                required
                id='EventState'
                name='EventState'
                label='State/Province/Region'
                fullWidth
                variant='standard'
              />
              {errors.EventState && <Box className='form-error'>Min Length: 2</Box>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...register('EventZip', { required: true, minLength: 5, pattern: /^[0-9]+$/ })}
                required
                id='EventZip'
                name='EventZip'
                label='Zip / Postal code'
                fullWidth
                variant='standard'
              />
              {errors.EventZip && <Box className='form-error'>Min Length: 5 digits</Box>}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                {...register('EventDate', { required: true })}
                required
                id='EventDate'
                name='EventDate'
                label='Event Date'
                type='date'
                fullWidth
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
               {...register('EventEndDate')}
                id='EventEndDate'
                name='EventEndDate'
                label='Event End Date'
                type='date'
                fullWidth
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register('EventWebsite', { minLength: 5 })}
                id='EventWebsite'
                name='EventWebsite'
                label='Event Website'
                fullWidth
                variant='standard'
              />
              {errors.EventWebsite && <Box className='form-error'>Min Length: 5</Box>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('PresentedBy', { minLength: 3 })}
                id='PresentedBy'
                name='PresentedBy'
                label='Presented By'
                fullWidth
                variant='standard'
              />
              {errors.PresentedBy && <Box className='form-error'>Min Length: 3</Box>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('EventLogo', { minLength: 5 })}
                id='EventLogo'
                name='EventLogo'
                label='Event Logo URL'
                fullWidth
                variant='standard'
              />
              {errors.EventLogo && <Box className='form-error'>Min Length: 5</Box>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('EventNotes')}
                id='EventNotes'
                name='EventNotes'
                label='Event Notes'
                multiline
                rows={3}
                fullWidth
                variant='standard'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('ContactEmail', { minLength: 5 })}
                id='ContactEmail'
                name='ContactEmail'
                label='Contact Email'
                fullWidth
                variant='standard'
              />
              {errors.ContactEmail && <Box className='form-error'>Min Length: 5</Box>}
            </Grid>
          </Grid>
        </CardContent>
        
        <CardActions sx={{ pt: 2, ml: 1.2 }}>   
          <Button type='submit' variant='outlined' sx={{ border: '1px solid #62d1de', mr: 1 }}>
            Submit Event
          </Button>

          <Button onClick={handleClick} variant='outlined' sx={{ border: '1px solid #62d1de' }}>
            Back to Map
          </Button>
        </CardActions>
      </Paper>
      </form>
    </Container>
  );
}

export default CreateEvent;