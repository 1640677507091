import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { getImage, convertDate, getEventStart } from './services/helpers';

function LocationPin({ event }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const days = moment(convertDate(event.EventDate)).diff(moment(), 'days');
  const countdown = days > 0 ? `T-${days + 1}` : 'T-MISSED';
  const pinStlye = days > 0 ? 'pin-icon' : 'pin-icon-grey';

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <Box>
      <Box 
        onClick={handlePopoverOpen} 
        className={pinStlye}
        onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      >
        {countdown}
      </Box>

      <Popover
        id='mouse-over-popover'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ transform: 'translate(-.2%, -.2%)' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Paper elevation={2} className='pin-box'>
          <CardMedia
            className='pin-img'
            component='img'
            image={getImage(event)}
            sx={{ height: 160 }}
          />

          <CardContent className='pin-card'>
            <Stack direction='row' justifyContent='space-between'>
              <Box className='pin-date'>
                {getEventStart(event.EventDate)}
              </Box>
              <Box className='pin-countdown'>
                {countdown} DAYS
              </Box>
            </Stack>
            
            <Box className='pin-name'>
              <Link to={`/show-event/${event.EventName.replaceAll(' ', '-')}/${event._id}`} target='_blank' rel='noopener noreferrer'>
                {event.EventName}
              </Link>
            </Box>

            <Box className='pin-city'>
              {event.EventCity}, {event.EventState}
            </Box>

            <Box className='pin-types'>
              {event.EventTypes.toString().replace(',', ', ')}
            </Box>
          </CardContent>
          
          <CardActions sx={{ justifyContent: 'end' }}>
            <Button 
              href={`/show-event/${event.EventName.replaceAll(' ', '-')}/${event._id}`}  
              variant='outlined' 
              size='small' 
              sx={{ border: '1px solid #62d1de' }}
              target='_blank' 
              rel='noopener noreferrer'
            >
              View Details
            </Button>
          </CardActions>
        </Paper>
      </Popover>
    </Box>
  );
}

export default LocationPin;