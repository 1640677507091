import { lazy, Suspense } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import SignIn from './components/admin/SignIn';
import ContactForm from './components/ContactForm';
import CreateEvent from './components/CreateEvent';
import EventDetails from './components/EventDetails';
import Home from './components/Home';
import MobileHome from './components/mobile/MobileHome';
import { AuthContextProvider, useAuthState } from './firebase';

import './components/styles/App.css';
import './components/styles/EventCard.css';
import './components/styles/FilterBar.css';
import './components/styles/Map.css';

const AdminPanel = lazy(() => import('./components/admin/AdminPanel'));

function App() {
  const isDesktop = useMediaQuery('(min-width: 700px)');

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}> 
          <AuthContextProvider>
            <BrowserRouter>
              <Routes>
                <Route exact path='/' element={isDesktop ? <Home /> : <MobileHome />} />
                <Route path='/create-event' element={<CreateEvent />} />
                <Route path='/show-event/:name/:id/:type?' element={<EventDetails />} />
                <Route path='/contact' element={<ContactForm />} />
                <Route path='/signin' element={<SignIn />} />
                <Route path='/admin' element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <AdminPanel />
                    </Suspense>
                  </PrivateRoute>} 
                />
              </Routes>
            </BrowserRouter>
          </AuthContextProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuthState();
  return isAuthenticated ? children : <Navigate to='/signin' />;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 86400000,
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: 'Oxanium'
  },  
  components: {
    MuiTextField: {
      defaultProps: {
        InputProps: {
          sx: {
            '&::before': {
              borderBottomColor: '#62d1de !important',
            },
            '&::after': {
              borderBottomColor: '#62d1de !important',
            },
          },
        },
      },
    },
  },
});

export default App;
