import React from 'react';

import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import { getImage, getUrl, getEventDates, removeHTML } from './services/helpers';

function EventDetails() {
  const isDesktop = useMediaQuery('(min-width: 700px)');
  const { name, id, type } = useParams();
  const navigate = useNavigate();
  
  const url = type == 'new' ? `${process.env.REACT_APP_API_URL}/newBikeEvents` : `${process.env.REACT_APP_API_URL}/bikeEvents`;
  const buttonSize = isDesktop ? 'medium' : 'small';

  const fetchEvent = async () => { 
    try {
      const response = await axios.get(`${url}/${id}`);
      console.log(response.data);
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
  };

  const { isLoading, data } = useQuery(`eventDetails:${id}`, fetchEvent);
  const event = data ? data : {};

  const title = name ? name.replaceAll('-', ' ') : 'Event Details';
  const content = `Get all the event details for ${event.EventName}, ${event.EventCity}, Event Types: ${event.EventTypes}`;

  const handleClick = () => {
    navigate('/');
    window.location.reload();
  };

  const eventTable = (
    <Paper elevation={1}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{event.EventName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{event.EventAddress}, {event.EventCity}, {event.EventState} {event.EventZip}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>
              {event.EventDate && getEventDates(event.EventDate, event.EventEndDate)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Website</TableCell>
            <TableCell>
              {event.EventWebsite && <a href={getUrl(event.EventWebsite)} target='_blank' rel='noopener noreferrer'>{event.EventWebsite}</a>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>BikeReg Page</TableCell>
            <TableCell>
              {event.EventPermalink && <a href={getUrl(event.EventPermalink)} target='_blank' rel='noopener noreferrer'>{event.EventPermalink}</a>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Categories</TableCell>
            <TableCell>{event.EventTypes && event.EventTypes.join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{event.EventNotes && removeHTML(event.EventNotes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Promoter</TableCell>
            <TableCell>{event.PresentedBy}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );

  return (
    <Container maxWidth='md' sx={{ mt: 2.5, mb: 2 }}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={content} />
      </Helmet>
      <Paper elevation={4} className='event-page'>
        <CardMedia
          className='event-details-img'
          sx={{ height: 400 }}
          image={getImage(event)}
        />
        <CardContent sx={{ pb: .7 }}>
          <Typography 
            className='event-details-title'
            sx={{ mb: 1.5 }} 
            variant='h4' 
            component='div'
          >
            {event.EventName}
          </Typography>

          {eventTable}
        </CardContent>

        <CardActions sx={{ ml: 1, pb: .5 }}>
          <Button onClick={handleClick} variant='outlined' size={buttonSize} sx={{ border: '1px solid #62d1de' }}>
            Back to Map
          </Button>
        </CardActions>
      </Paper>
    </Container>
  );
}

export default EventDetails;


