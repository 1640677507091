import { convertCategories, sortByCenterDistance, convertDate } from './helpers';

export function filterEventsByMap(mappedEvents, mapRef, neBounds, swBounds) {
  if (mapRef) {
    return mappedEvents.filter(event => 
      (neBounds.lat > event.Latitude && event.Latitude > swBounds.lat) &&
      (neBounds.lng > event.Longitude && event.Longitude > swBounds.lng)
    );
  }
  return mappedEvents;
}

export function filterEventsByCategory(categories, sortedEventList) {
  if (categories.length > 0 && !categories.includes('All Cycling')) {
    return sortedEventList.filter(event => {
      if (categories.length == 1) {
        return event.EventTypes.includes(categories[0]);
      } 
      else if (categories.length > 1) {
        return categories.some(category => event.EventTypes.includes(category));
      }
    });
  }
  return sortedEventList;
}

export function filterEventsByDateRange(date1, date2, events) {
  return events.filter(event => Date.parse(convertDate(event.EventDate)) > date1 && Date.parse(convertDate(event.EventEndDate)) < date2);
}

export function getMappedEvents(swBounds, dateEventList, mappedEvents) {
  return swBounds ? dateEventList : mappedEvents;
}

export function getMappedMarkers(swBounds, dateEventList, mappedEvents) {
  return swBounds ? dateEventList : mappedEvents;
}

export function filteredEvents(events, state, mapState) {
  const { categories, date1, date2 } = state;
  const { mapRef, updatedCenter, neBounds, swBounds } = mapState;

  const updatedEvents = convertCategories(events);

  const mappedEvents = filterEventsByMap(updatedEvents, mapRef, neBounds, swBounds);

  const sortedEventList = updatedCenter ? sortByCenterDistance(updatedCenter, mappedEvents) : mappedEvents;

  const categoryEventList = filterEventsByCategory(categories, sortedEventList);

  const dateEventList = filterEventsByDateRange(date1, date2, categoryEventList);

  const mappedEventList = getMappedEvents(swBounds, dateEventList, mappedEvents);

  const mappedMarkers = getMappedMarkers(swBounds, dateEventList, mappedEvents);

  return { mappedEventList, mappedMarkers };
}
