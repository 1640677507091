import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

function HomeModal({ open, handleClose }) {
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Paper sx={modalStyle}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: -1, top: -1, color: '#333 !important' }}>
            <CloseIcon />
          </IconButton>
  
          <Box sx={{ m: 1, fontSize: '1.2rem' }}>
            <Box sx={{ textAlign: 'center', mb: 1.5, fontWeight: 'bold', fontSize: '1.4rem', color: '#333' }}>Beta Testing Grounds</Box>
            <Box sx={{ mb: 1, fontWeight: 'bold' , color: '#333' }}>Upcoming Release Features:</Box>
            <Box sx={{ color: '#333' }}>- Group ride listings across the United States</Box>
            <Box sx={{ color: '#333' }}>- Reminder system for users concerning previous missed events</Box>
            <Box sx={{ color: '#333' }}>- Functionality in Canada, Australia, Iceland, and Germany</Box>            
            <Box sx={{ color: '#333' }}>- More Map Overlays: Music, Food, and Entertainment</Box>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  padding: 2.5,
  boxShadow: 24,
  borderRadius: 4
};

export default HomeModal;