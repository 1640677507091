import React, { useEffect } from 'react';

function AdsComponent({ dataAdSlot }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch (error) {
      console.log(error.message)
    }
  }, []);

  return (
    <>
      <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='ca-pub-1433630846580009'
          data-ad-slot={dataAdSlot}
          data-ad-format='auto'
          data-full-width-responsive='true'>
      </ins>
    </>
  );
};

export default AdsComponent;