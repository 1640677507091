import React, { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GoogleMap, LoadScript, OverlayView, useGoogleMap } from '@react-google-maps/api';

import styles from './assets/mapStyles';
import LocationPin from './LocationPin';

function Map({ events, center, zoomLevel, handleOnLoad, getMapBounds, modalButton }) {
  const isDesktop = useMediaQuery('(min-width: 700px)');

  const containerStyle = isDesktop ? 
    { width: '100%', height: '100%' } :
    { width: window.innerWidth, height: window.innerHeight - 201 };

  const mapOptions = { 
    minZoom: 7,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    mapTypeId: 'terrain',
    styles: styles
  };

  return (
    <Box className='google-map'>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
      >
        <GoogleMap
          onLoad={handleOnLoad}
          onBoundsChanged={getMapBounds}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoomLevel}
          options={mapOptions} 
        >
          {events && events.map((event, i) => 
            <OverlayView
              position={{ lat: event.Latitude, lng: event.Longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              key={i}
            >
              <LocationPin event={event} />
            </OverlayView>
          )}

          {!isDesktop &&
            <MapControl position='LEFT_TOP'>
              {modalButton && modalButton}
            </MapControl>
          }
        </GoogleMap>
      </LoadScript>
    </Box>
  );
}

function MapControl(props) {
  const map = useGoogleMap();
  const ref = useRef();

  useEffect(() => {
    if (map && ref) {
      map.controls[window.google.maps.ControlPosition[props.position]].push(
        ref.current
      );
    }
  }, [map, ref]);
  
  return <div ref={ref}>{props.children}</div>;
};

export default Map;
