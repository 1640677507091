import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';

import { categories } from './services/constants';

function CategoryForm({ handleSelect }) {
  const isDesktop = useMediaQuery('(min-width: 480px)');
  const borderStyle = isDesktop ? { borderLeft: '1px solid #62d1de' } :
    { border: '1px solid #62d1de', borderRadius: '5px', background: '#222222' };

  return (
    <Stack sx={{ minWidth: 170 }}>
      <Autocomplete
        multiple
        id='tags-outlined'
        options={categories}
        getOptionLabel={(option) => option}
        size='small'
        onChange={(e, value) => handleSelect(e, value)}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label='Categories'
            sx={{
              input: { color: '#62d1de', fontWeight: 'bold' },
              label: { color: '#62d1de' },
              svg: { color: '#62d1de' },
              ...borderStyle
            }}
          />
        )}
      />
    </Stack>
  );
}

export default CategoryForm;

