import { addDays } from '../services/helpers';

export const initialState = { 
  keyword: null,
  date1: Date.now(),
  date2: addDays(Date.now(), 100),
  categories: [],
  alert: null
};

export const initialMapState = { 
  mapRef: null,
  center: { lat: null, lng: null },
  updatedCenter: { lat: null, lng: null },
  zoomLevel: 8,
  neBounds: { lat: null, lng: null },
  swBounds: { lat: null, lng: null },
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  return { ...state, [type]: payload };
};
