import React, { useReducer, useState } from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';

import EventsModal from './EventsModal';
import AccountMenu from '../AccountMenu';
import CategoryForm from '../CategoryForm';
import EventCard from '../EventCard';
import Map from '../Map';
import SearchBar from '../SearchBar';
import { content } from '../services/constants';
import { filteredEvents } from '../services/filters';
import { getMapBounds, handleSearch, defaultCenter, addDays } from '../services/helpers';  
import { initialState, initialMapState, reducer } from '../store/store';

function MobileHome() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [mapState, dispatchMap] = useReducer(reducer, initialMapState);
  const [open, setOpen] = useState(false);
  const { mapRef, center, zoomLevel } = mapState;

  const fetchAllEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/bikeEvents`);
      console.log('count: ' + response.data.length);
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
  };

  const { isLoading, data: events } = useQuery('bikeEvents:all', fetchAllEvents);
  const { mappedEventList, mappedMarkers } = events ? filteredEvents(events, state, mapState) : { mappedEventList: [], mappedMarkers: [] };
  const mappedEvents = mappedEventList.map((event, k) => <EventCard event={event} key={k} />);

  const handleOnLoad = (map) => dispatchMap({ type: 'mapRef', payload: map });

  const handleSlider = (days) => {
    const date2 = addDays(Date.now(), days);
    dispatch({ type: 'date2', payload: date2 });
  };

  const handleSelect = (e, value) => dispatch({ type: 'categories', payload: value });

  const searchLocation = (e, keyword) => {
    if (e.cancelable) e.preventDefault();
    handleSearch(keyword, dispatchMap, 10);
    dispatch({ type: 'keyword', payload: keyword });
  }; 

  const ModalButton = (
    <Paper sx={{ ml: 1, mt: 1, p: '0em .15em' }}>
      <Button
        size='small'
        onClick={() => setOpen(true)}
      >
        View Events
      </Button>   
    </Paper>
  );

  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Helmet>
        <title>Pedal Radar</title>
        <meta name='description' content={content} />
      </Helmet>

      <Grid item sm={12} sx={{ p: '.85em 1.2em .9em 1.2em' }}>
        <Stack 
          direction='row' 
          justifyContent='space-between'
        >
          <Box className='events-title'>
            Cycling Events
          </Box>
          <AccountMenu />
        </Stack>
        
        <Stack sx={{ alignItems: 'start' }}>     
          <Button href='/create-event' className='blinking'>
            Register Event
          </Button>
        </Stack>

        <Stack 
          sx={{ mb: 1.7 }} 
          alignItems='center'
        >
          <SearchBar handleClick={searchLocation} />
        </Stack>

        <Stack 
          spacing={2.6}
          direction='row' 
          alignItems='center'
        >
          <Box sx={{ width: 200 }}>
            <Stack 
              spacing={1} 
              direction='row' 
              alignItems='center'
            >
              <CalendarMonthIcon />
              <Slider 
                size='small'
                defaultValue={90}
                step={10}
                max={90}
                valueLabelDisplay='auto'
                valueLabelFormat={value => `${value} days`}
                onChange={e => handleSlider(e.target.value)} 
              />
            </Stack>
          </Box>

          <CategoryForm handleSelect={handleSelect} />
        </Stack>
      </Grid>
      
      <Grid item>
        {isLoading && <LinearProgress sx={{ mt: .7 }} />}

        <Map
          events={mappedMarkers}
          center={defaultCenter(center)}
          zoomLevel={zoomLevel} 
          handleOnLoad={map => handleOnLoad(map)}
          getMapBounds={() => getMapBounds(mapRef, dispatchMap)}
          modalButton={ModalButton}
        />
      </Grid>

      <EventsModal
        events={mappedEvents}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Grid>
  );
}

export default MobileHome;
