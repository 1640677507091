import React, { useReducer } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Alert from './Alert';
import { content } from './services/constants';
import { initialState, reducer } from './store/store';

function ContactForm() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { alert } = state;

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {
    axios.post(`${process.env.REACT_APP_API_URL}/bikeEvents/contact`, data)
      .then(res => {
        dispatch({ type: 'alert', payload: 'Message Sent!' });
        reset();
        console.log(res.data);
      })
      .catch(error => {
        dispatch({ type: 'alert', payload: error.response.data.message });
        reset();
        console.log(error.response);
      });
  };

  const handleClick = () => {
    navigate('/');
    window.location.reload();
  };

  const resetAlert = () => dispatch({ type: 'alert', payload: null });

  return (
    <Container maxWidth='md' sx={{ mt: 2.5, mb: 2 }}>
      <Helmet>
        <title>Contact Us</title>
        <meta name='description' content={content} />
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={4} className='event-page' sx={{ color: '#bbbbbb' }}>
        <Typography variant='h5' sx={{ m: '.25em 0em 0em .72em', fontSize: '1.57rem' }}>
          Contact Us
        </Typography>
        <Box>
          {alert && <Alert alert={alert} resetAlert={resetAlert} />}  
        </Box>
      
        <CardContent sx={{ p: '.4em 1.2em .5em 1.2em' }}> 
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                {...register('name', { required: true, minLength: 3 })}
                required
                id='name'
                name='name'
                label='Name'
                fullWidth
                variant='standard'
              />
              {errors.name && <Box className='form-error'>Min Length: 3</Box>}
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...register('email', { required: true, minLength: 5 })}
                required
                id='email'
                name='email'
                label='Email Address'
                fullWidth
                variant='standard'
              />
              {errors.email && <Box className='form-error'>Min Length: 5</Box>}
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                {...register('message', { required: true, minLength: 2 })}
                required
                id='message'
                name='message'
                label='Message'
                multiline
                rows={3}
                fullWidth
                variant='standard'
              />
              {errors.message && <Box className='form-error'>Min Length: 2</Box>}
            </Grid>
          </Grid>
        </CardContent>
        
        <CardActions sx={{ pt: 2, ml: 1.2 }}>   
          <Button type='submit' variant='outlined' sx={{ border: '1px solid #62d1de', mr: 1 }}>
            Submit
          </Button>

          <Button onClick={handleClick} variant='outlined' sx={{ border: '1px solid #62d1de' }}>
            Back to Map
          </Button>
        </CardActions>
      </Paper>
      </form>
    </Container>
  );
}

export default ContactForm;