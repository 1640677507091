import React, { useReducer, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';

import AccountMenu from './AccountMenu';
import AdsComponent from './AdsComponent';
import Alert from './Alert';
import CategoryForm from './CategoryForm';
import EventCard from './EventCard';
import HomeModal from './HomeModal';
import Map from './Map';
import SearchBar from './SearchBar';
import { content } from './services/constants';
import { filteredEvents } from './services/filters';
import { getMapBounds, handleSearch, resetFilters, defaultCenter, getSearchString } from './services/helpers';
import { initialState, initialMapState, reducer } from './store/store';

function Home() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [mapState, dispatchMap] = useReducer(reducer, initialMapState);
  const [open, setOpen] = useState(false);

  const { keyword, date1, date2, categories, alert } = state;
  const { mapRef, center, zoomLevel } = mapState;

  const fetchAllEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/bikeEvents`);
      console.log('count: ' + response.data.length);
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
  };

  const { isLoading, data: events } = useQuery('bikeEvents:all', fetchAllEvents);
  const { mappedEventList, mappedMarkers } = events ? filteredEvents(events, state, mapState) : { mappedEventList: [], mappedMarkers: [] };
  const mappedEvents = mappedEventList.map((event, k) => <EventCard event={event} key={k} />);

  const handleOnLoad = (map) => dispatchMap({ type: 'mapRef', payload: map });

  const handleSelect = (e, value) => {
    dispatch({ type: 'categories', payload: value });
    dispatch({ type: 'alert', payload: getSearchString(keyword, value, date1, date2) });
  };

  const handleDate = (type, date) => {
    const searchDate1 = (type == 'date1' ? date : date1);
    const searchDate2 = (type == 'date2' ? date : date2);
    dispatch({ type: type, payload: Date.parse(date) });
    dispatch({ type: 'alert', payload: getSearchString(keyword, categories, searchDate1, searchDate2) });
  };

  const searchLocation = (e, keyword) => {
    e.preventDefault();
    handleSearch(keyword, dispatchMap, 11);
    dispatch({ type: 'keyword', payload: keyword });
    dispatch({ type: 'alert', payload: getSearchString(keyword, categories, date1, date2) });
  }; 

  const resetAlert = () => dispatch({ type: 'alert', payload: null });

  return (
    <Grid container>
      <Helmet>
        <title>Pedal Radar</title>
        <meta name='description' content={content} />
      </Helmet>

      <HomeModal open={open} handleClose={() => setOpen(false)} />

      <Grid item sm={12}      
        direction='row'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mt: 2.5, mb: 1.7, maxHeight: 68 }}
      >
        <Box className='events-title'>
          Cycling Events
        </Box>

        <Box        
          direction='row'
          display='flex'
          justifyContent='center'
          alignItems='center'
          className='filter-bar'
        >
          <SearchBar handleClick={searchLocation} />

          <CategoryForm handleSelect={handleSelect} />
          
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className='date-picker'>
              <DatePicker
                // label='Begin Date'
                value={date1}
                inputFormat='MMM D'
                disableMaskedInput
                onChange={(newValue) => handleDate('date1', newValue.$d)}
                renderInput={(params) => 
                  <TextField size='small' {...params} 
                    sx={{
                      svg: { color: '#62d1de' },
                      input: { color: '#62d1de' },
                      label: { color: '#62d1de' },
                      borderLeft: '1px solid #62d1de' 
                    }}
                  />}
              />
            </Box>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className='date-picker'>
              <DatePicker
                // label='End Date'
                value={date2}
                inputFormat='MMM D'
                disableMaskedInput
                onChange={(newValue) => handleDate('date2', newValue.$d)}
                renderInput={(params) => 
                  <TextField size='small' {...params} 
                    sx={{
                      svg: { color: '#62d1de' },
                      input: { color: '#62d1de' },
                      label: { color: '#62d1de' },
                      borderLeft: '1px solid #62d1de' 
                    }}
                  />}
              />
            </Box>
          </LocalizationProvider>

          {/* <Box sx={{ borderLeft: '1px solid #62d1de' }}>
            <Button onClick={() => resetFilters(dispatch)}>Reset</Button>
          </Box> */}
        </Box>
        
        <Box className='account-menu-box'>
          <AccountMenu setOpen={setOpen} />
        </Box>
      </Grid>

      <Grid item sm={4} sx={{ width: 600 }}> 
        {isLoading && <LinearProgress sx={{ m: '.5em' }} />}
        {alert && <Alert alert={alert} resetAlert={resetAlert} />}

        <List sx={{ maxHeight: '90.6vh', overflow: 'auto' }}>
          {mappedEvents}
        </List>
      </Grid>
      
      <Grid item sm={8}>
        <Map
          events={mappedMarkers}
          center={defaultCenter(center)}
          zoomLevel={zoomLevel} 
          handleOnLoad={map => handleOnLoad(map)}
          getMapBounds={() => getMapBounds(mapRef, dispatchMap)}
        />
      </Grid>
      {/* <Grid item sm={1.5} sx={{ display: 'flex', mt: 1, pl: 1 }}>
        <AdsComponent dataAdSlot={'X7XXXXXX5X'} />
        <img src='https://lh3.googleusercontent.com/GifJcBsIaBUbAqYb_ymnzDXlLiSTyUUkFfMRFnpy8SjQZXR8e4lyUHHrQDuxL4EqTkI=w178-rwa'></img>
      </Grid> */}
    </Grid>
  );
}

export default Home;


